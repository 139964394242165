
.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

.form-group textarea {
    resize: vertical;
    height: 100px;
}

.form-group .country-code {
    width: 23%;
}

.form-group .phone-number {
    width: 77%;
}

.form-group button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}


.row.add-department {
    align-items: center;
    background: #e6eaf4;
    padding: 15px;
    margin: 4px;
    border-radius: 5px;
  }
  .department-logo {
    display: flex;
    gap: 13px;
    align-items: center;
  }


  .department-logo img {
  max-width: 150px;
object-fit: contain;
  }

  .dep-modal-logo
  {
    max-width: 150px;
object-fit: contain;
margin: 5px;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    width: 38%;
    min-width: 350px;
  }
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(15, 15, 15, 0.75) !important;
    z-index: 999;
  }
  .edit-dep-logo
  {
    background: #d3e4ec;
padding: 5px;
border-radius: 5px;
  }