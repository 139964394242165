@import url('https://rsms.me/inter/inter.css');
/* .container {
    max-width: 400px;
    margin: 0 auto;
} */

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

.form-group textarea {
    resize: vertical;
    height: 100px;
}

.form-group .country-code {
    width: 10%;
}

.form-group .phone-number {
    width: 90%;
}

.form-group button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}



/**
 * Main wrapper
 */
.select-search-container {
    --select-search-background: #fff;
    --select-search-border: #dce0e8;
    --select-search-selected: #1e66f5;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-inverted-text: var(--select-search-background);
    --select-search-highlight: #eff1f5;
    --select-search-font: 'Inter', sans-serif;

    /* width: 300px; */
    position: relative;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
    .select-search-container {
        --select-search-font: 'Inter var', sans-serif;
    }
}

@media (prefers-color-scheme: dark) {
    .select-search-container {
        --select-search-background: #000;
        --select-search-border: #313244;
        --select-search-selected: #89b4fa;
        --select-search-text: #fff;
        --select-search-subtle-text: #a6adc8;
        --select-search-highlight: #1e1e2e;
    }
}

body.is-dark-mode .select-search-container {
    --select-search-background: #000;
    --select-search-border: #313244;
    --select-search-selected: #89b4fa;
    --select-search-text: #fff;
    --select-search-subtle-text: #a6adc8;
    --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
    --select-search-background: #fff;
    --select-search-border: #dce0e8;
    --select-search-selected: #1e66f5;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
    box-sizing: inherit;
}

.select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    height: 48px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: var(--select-search-background);
    border: 2px solid var(--select-search-border);
    color: var(--select-search-text);
    border-radius: 3px;
    outline: none;
    font-family: var(--select-search-font);
    font-size: 16px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 48px;
    letter-spacing: 0.01rem;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
    margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
    border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search-input[readonly] {
    cursor: pointer;
}

.select-search-is-disabled .select-search-input {
    cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input,
.select-search-container:not(.select-search-is-disabled) .select-search-input:hover {
    border-color: var(--select-search-selected);
}

.select-search-select {
    background: var(--select-search-background);
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border: 2px solid var(--select-search-border);
    overflow: auto;
    max-height: 360px;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
    position: absolute;
    z-index: 2;
    top: 58px;
    right: 0;
    left: 0;
    border-radius: 3px;
    display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
    display: block;
}

.select-search-has-focus .select-search-select {
    border-color: var(--select-search-selected);
}

.select-search-options {
    list-style: none;
}

.select-search-option,
.select-search-not-found {
    display: block;
    height: 42px;
    width: 100%;
    padding: 0 16px;
    background: var(--select-search-background);
    border: none;
    outline: none;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.01rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
    background: var(--select-search-highlight);
}

.select-search-is-selected {
    font-weight: bold;
    color: var(--select-search-selected);
}

.select-search-group-header {
    font-size: 12px;
    text-transform: uppercase;
    background: var(--select-search-border);
    color: var(--select-search-subtle-text);
    letter-spacing: 0.1rem;
    padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
    margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
    margin-bottom: 10px;
}


























ul.timeline {
    list-style-type: none;
    position: relative;
}

ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: -15px;
    width: 2px;
    height: 100%;
    z-index: 400;
}

ul.timeline>li {
    margin: 20px 0;
    padding-left: 20px;
}

ul.timeline>li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid ;
    left: -25px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

/* .alert.alert-warning::before {
    border: 3px solid #f2b706;
}

.alert.alert-success::before {
    border: 3px solid #03a737;
}

.alert.alert-danger::before {
    border: 3px solid #d52e10;
} */

.timeline-text {
    color: #303030;
}

.lead-name {
    background: #e8e8e8;
    padding: 14px;
    border-radius: 3px;
    font-size: 21px;
    margin-bottom: 25px;
    width: 100%;
}

.status-box {
    padding: 15px;
    background: #e8e8e8;
    display: block;
    border-radius: 3px;
    margin-bottom: 15px;
}


.count {
    width: 70px;
  background: #2734c1;
  height: 70px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 6px;
  margin-top: -19px;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  flex-direction: column;
  line-height: 1;
  color: #fff;
  }


  .count span {
   
    font-size: 30px;
  }
  .row.bg-ad {
    background: #cccccc4d;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    position: relative;
  }

  .close-ad
  {
    position:absolute;
    top:8px;
    right: 8px;
  }